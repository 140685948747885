const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'side-menu-dashboard',
        title: '会员中心',
        url:'/Dashboard'
      },
      {
        icon: 'BoxIcon',
        pageName: 'profile',
        title: '个人资料',
        url:'/Dashboard/profile'
      },
      {
        icon: 'GridIcon',
        // pageName: 'myApp',
        title: '我的应用',
        // url:'/Dashboard/MyApp',
        subMenu: [
          {
            icon: 'BoxIcon',
            title: '综合应用',
            pageName: 'myApp'
          },
          {
            icon: 'ActivityIcon',
            title: '供应链应用',
            pageName: 'supplyChainApp'
          }
        ]
      },
      {
        icon: 'TruckIcon',
        pageName: 'order',
        title: '订单列表',
        url:'/Dashboard/order'
      },
      {
        icon: 'DollarSignIcon',
        pageName: 'side-menu-point-of-sale',
        title: '财务管理',
        url:'/Dashboard/point-of-sale'
      },
      {
        icon: 'UsersIcon',
        pageName: 'invitation',
        title: '推广赚钱',
        url:'/Dashboard/invitation'
      },
      {
        icon: 'MessageSquareIcon',
        pageName: 'side-menu-chat',
        title: '消息中心',
        url:'/Dashboard/chat'
      }
    ]
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
