/**
 *
 */
export const fSystem = function ( value ) {
    if (value === 'android') return '安卓'
    if (value === 'ios') return '苹果'
    if (value === 'applet') return '小程序'
    if (value === 'web') return 'H5'
}


export const formatMoney = function (number,decimals = 2,decPoint = ".",thousandsSep = ",") {
    number = (number + "").replace(/[^0-9+-Ee.]/g, "");
    let n = !isFinite(+number) ? 0 : +number;
    let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    let sep = typeof thousandsSep === "undefined" ? "," : thousandsSep;
    let dec = typeof decPoint === "undefined" ? "." : decPoint;
    let s = "";
    let toFixedFix = function (n, prec) {
      let k = Math.pow(10, prec);
      return "" + Math.ceil(n * k) / k;
    };
    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    let re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
      s[0] = s[0].replace(re, "$1" + sep + "$2");
    }
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || "";
      s[1] += new Array(prec - s[1].length + 1).join("0");
    }

    return s.join(dec);
  }