import Vue from 'vue';
import VueRouter from 'vue-router';
import Cookies from "js-cookie";
import request from '../utils/request'


Vue.use(VueRouter);



const routes = [

	{
		path: '/',	// 首页
		name: 'p_index',
		meta: { title: '智莺' },
		component: () => import('@/views/index/index_new')
	},
	{
		path: '/index/new-guide',	// 最新导购
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/guide/guide')
	},
	{
		path: '/index/new-card',	//权益卡
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/card/card')
	},
	{
		path: '/index/new-life',	// 生活
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/life/life')
	},
	{
		path: '/index/new-travel',	// 出行
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/travel/travel')
	},
	{
		path: '/index/take-away',	// 外卖
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/take-away/take-away')
	},
	{
		path: '/index/order',	// cps 点餐
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/order/order')
	},
	{
		path: '/index/block-chain',	// cps 区块链
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/block-chain/block-chain')
	},
	{
		path: '/index/new-mall',	// 自营商城
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/mall/mall')
	},
	{
		path: '/index/new-o2o-mall',	// o2o 商城
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/o2o-mall/o2o-mall')
	},
	{
		path: '/index/new-member-mall',	//  会员商城
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/member-small/member-small')
	},
	{
		path: '/index/new-b2c-mall',	// b2c商城
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/b2c-mall/b2c-mall')
	},
	{
		path: '/index/scm',	// 供应链系统
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/scm/scm')
	},
	{
		path: '/index/new-live-video',	// 直播短视频
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/live-video/live-video')
	},
	{
		path: '/index/team-selection',	// 团长选品
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/team-selection/team-selection')
	},
	{
		path: '/index/new-im',	// im
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/im/im')
	},
	{
		path: '/index/chat-gpt',	// chat-gpt
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/chat-gpt/chat-gpt')
	},
	{
		path: '/index/new-advert',	//广告任务
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/advert/advert')
	},
	{
		path: '/index/cloud-issuance',	// 云发单
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/cloud-issuance/cloud-issuance')
	},
	{
		path: '/index/short-play',	// 短剧
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/short-play/short-play')
	},
	{
		path: '/index/new-game',	// 游戏活动
		name: 'p_index',
		meta: { title: '智莺首页' },
		component: () => import('@/views/index/game/game')
	},
	{
		path: '/index/new',	// 首页
		name: 'p_index',
		meta: { title: '智莺' },
		component: () => import('@/views/index/index_new')
	},
	{
		path: '/index/ability',
		name: 'p_index',
		meta: { title: '能力板块' },
		component: () => import('@/views/ability/ability')
	},
	{
		path: '/index/recommend',
		name: 'p_index',
		meta: { title: '推介有奖' },
		component: () => import('@/views/recommend/recommend')
	},
	{
		path: '/m_index', // 手机端首页
		name: 'm_index',
		meta: { title: '智莺' },
		component: () => import('@/views/index/index_new')
	},
	{
		path: '/index/guide',
		name: 'guide',
		meta: { title: '导购' },
		component: () => import('@/views/guide/guide')
	},
	{
		path: '/index/card',
		name: 'card',
		meta: { title: '权益卡' },
		component: () => import('@/views/card/card')
	},
	{
		path: '/index/local',
		name: 'local',
		meta: { title: '本地生活' },
		component: () => import('@/views/local/local')
	},
	{
		path: '/index/o2o',
		name: 'o2o',
		meta: { title: '数字门店' },
		component: () => import('@/views/o2o/o2o')
	},
	{
		path: '/index/institution',
		name: 'institution',
		meta: { title: '制度模式' },
		component: () => import('@/views/institution/institution')
	},
	{
		path: '/index/mall',
		name: 'mall',
		meta: { title: '自营商城' },
		component: () => import('@/views/mall/mall')
	},
	{
		path: '/index/more-mall',
		name: 'more-mall',
		meta: { title: '多任务商城' },
		component: () => import('@/views/more-mall/more-mall')
	},
	{
		path: '/index/provider',
		name: 'provider',
		meta: { title: '供应链' },
		component: () => import('@/views/provider/provider')
	},
	{
		path: '/index/block',
		name: 'block',
		meta: { title: '区块链任务' },
		component: () => import('@/views/block/block')
	},
	{
		path: '/index/live-video',
		name: 'live-video',
		meta: { title: '多任务商城' },
		component: () => import('@/views/live-video/live-video')
	},
	{
		path: '/index/game',
		name: 'game',
		meta: { title: '多任务商城' },
		component: () => import('@/views/game/game')
	},
	{
		path: '/login',
		name: 'login',
		meta: { title: '用户登录' },
		component: () => import('@/views/Login')
	},
	{
		name: 'Dashboard',
		path: '/Dashboard',
		component: () => import(/* webpackChunkName: 'main' */ '@/layouts/SideMenu'),
		children: [
			{
				path: '/',
				name: 'side-menu-dashboard',
				meta: { title: '会员中心' },
				component: () => import('@/views/Dashboard')
			},
			{
				path: 'inbox',
				name: 'side-menu-inbox',
				meta: { title: '管理中心' },
				component: () => import('@/views/Inbox')
			},
			{
				path: 'file-manager',
				name: 'side-menu-file-manager',
				meta: { title: '文件管理' },
				component: () => import('@/views/FileManager')
			},
			{
				path: 'point-of-sale',
				name: 'side-menu-point-of-sale',
				meta: { title: '财务管理' },
				component: () => import('@/views/PointOfSale')
			},
			{
				path: 'order',
				name: 'order',
				meta: { title: '订单列表' },
				component: () => import('@/views/order')
			},
			{
				path: 'chat',
				name: 'side-menu-chat',
				meta: { title: '消息中心' },
				component: () => import('@/views/Chat')
			},
			{
				path: 'post',
				name: 'side-menu-post',
				meta: { title: '管理中心' },
				component: () => import('@/views/Post')
			},
			{
				path: 'crud-data-list',
				name: 'side-menu-crud-data-list',
				meta: { title: '管理中心' },
				component: () => import('@/views/CrudDataList')
			},
			{
				path: 'crud-form',
				name: 'side-menu-crud-form',
				meta: { title: '管理中心' },
				component: () => import('@/views/CrudForm')
			},
			{
				path: 'users-layout-1',
				name: 'side-menu-users-layout-1',
				meta: { title: '管理中心' },
				component: () => import('@/views/UsersLayout1')
			},
			{
				path: 'users-layout-2',
				name: 'side-menu-users-layout-2',
				meta: { title: '管理中心' },
				component: () => import('@/views/UsersLayout2')
			},
			{
				path: 'users-layout-3',
				name: 'side-menu-users-layout-3',
				meta: { title: '管理中心' },
				component: () => import('@/views/UsersLayout3')
			},
			{
				path: 'profile-overview-1',
				name: 'side-menu-profile-overview-1',
				meta: { title: '管理中心' },
				component: () => import('@/views/ProfileOverview1')
			},
			{
				path: 'profile-overview-2',
				name: 'side-menu-profile-overview-2',
				meta: { title: '管理中心' },
				component: () => import('@/views/ProfileOverview2')
			},
			{
				path: 'profile-overview-3',
				name: 'side-menu-profile-overview-3',
				component: () => import('@/views/ProfileOverview3')
			},
			{
				path: 'wizard-layout-1',
				name: 'side-menu-wizard-layout-1',
				meta: { title: '管理中心' },
				component: () => import('@/views/WizardLayout1')
			},
			{
				path: 'wizard-layout-2',
				name: 'side-menu-wizard-layout-2',
				meta: { title: '管理中心' },
				component: () => import('@/views/WizardLayout2')
			},
			{
				path: 'wizard-layout-3',
				name: 'side-menu-wizard-layout-3',
				meta: { title: '管理中心' },
				component: () => import('@/views/WizardLayout3')
			},
			{
				path: 'blog-layout-1',
				name: 'side-menu-blog-layout-1',
				meta: { title: '管理中心' },
				component: () => import('@/views/BlogLayout1')
			},
			{
				path: 'blog-layout-2',
				name: 'side-menu-blog-layout-2',
				meta: { title: '管理中心' },
				component: () => import('@/views/BlogLayout2')
			},
			{
				path: 'blog-layout-3',
				name: 'side-menu-blog-layout-3',
				meta: { title: '管理中心' },
				component: () => import('@/views/BlogLayout3')
			},
			{
				path: 'pricing-layout-1',
				name: 'side-menu-pricing-layout-1',
				meta: { title: '管理中心' },
				component: () => import('@/views/PricingLayout1')
			},
			{
				path: 'pricing-layout-2',
				name: 'side-menu-pricing-layout-2',
				meta: { title: '管理中心' },
				component: () => import('@/views/PricingLayout2')
			},
			{
				path: 'invoice-layout-1',
				name: 'side-menu-invoice-layout-1',
				meta: { title: '管理中心' },
				component: () => import('@/views/InvoiceLayout1')
			},
			{
				path: 'invoice-layout-2',
				name: 'side-menu-invoice-layout-2',
				meta: { title: '管理中心' },
				component: () => import('@/views/InvoiceLayout2')
			},
			{
				path: 'faq-layout-1',
				name: 'side-menu-faq-layout-1',
				meta: { title: '管理中心' },
				component: () => import('@/views/FaqLayout1')
			},
			{
				path: 'faq-layout-2',
				name: 'side-menu-faq-layout-2',
				meta: { title: '管理中心' },
				component: () => import('@/views/FaqLayout2')
			},
			{
				path: 'faq-layout-3',
				name: 'side-menu-faq-layout-3',
				meta: { title: '管理中心' },
				component: () => import('@/views/FaqLayout3')
			},
			{
				path: 'update-profile',
				name: 'side-menu-update-profile',
				meta: { title: '管理中心' },
				component: () => import('@/views/UpdateProfile')
			},
			{
				path: 'change-password',
				name: 'side-menu-change-password',
				component: () => import('@/views/ChangePassword')
			},
			{
				path: 'regular-table',
				name: 'side-menu-regular-table',
				meta: { title: '管理中心' },
				component: () => import('@/views/RegularTable')
			},
			{
				path: 'tabulator',
				name: 'side-menu-tabulator',
				meta: { title: '管理中心' },
				component: () => import('@/views/Tabulator')
			},
			{
				path: 'accordion',
				name: 'side-menu-accordion',
				meta: { title: '管理中心' },
				component: () => import('@/views/Accordion')
			},
			{
				path: 'button',
				name: 'side-menu-button',
				meta: { title: '管理中心' },
				component: () => import('@/views/Button')
			},
			{
				path: 'modal',
				name: 'side-menu-modal',
				meta: { title: '管理中心' },
				component: () => import('@/views/Modal')
			},
			{
				path: 'alert',
				name: 'side-menu-alert',
				meta: { title: '管理中心' },
				component: () => import('@/views/Alert')
			},
			{
				path: 'progress-bar',
				name: 'side-menu-progress-bar',
				meta: { title: '管理中心' },
				component: () => import('@/views/ProgressBar')
			},
			{
				path: 'tooltip',
				name: 'side-menu-tooltip',
				meta: { title: '管理中心' },
				component: () => import('@/views/Tooltip')
			},
			{
				path: 'dropdown',
				name: 'side-menu-dropdown',
				meta: { title: '管理中心' },
				component: () => import('@/views/Dropdown')
			},
			{
				path: 'toast',
				name: 'side-menu-toast',
				meta: { title: '管理中心' },
				component: () => import('@/views/Toast')
			},
			{
				path: 'typography',
				name: 'side-menu-typography',
				meta: { title: '管理中心' },
				component: () => import('@/views/Typography')
			},
			{
				path: 'icon',
				name: 'side-menu-icon',
				meta: { title: '管理中心' },
				component: () => import('@/views/Icon')
			},
			{
				path: 'loading-icon',
				name: 'side-menu-loading-icon',
				meta: { title: '管理中心' },
				component: () => import('@/views/LoadingIcon')
			},
			{
				path: 'regular-form',
				name: 'side-menu-regular-form',
				meta: { title: '管理中心' },
				component: () => import('@/views/RegularForm')
			},
			{
				path: 'datepicker',
				name: 'side-menu-datepicker',
				meta: { title: '管理中心' },
				component: () => import('@/views/Datepicker')
			},
			{
				path: 'tail-select',
				name: 'side-menu-tail-select',
				meta: { title: '管理中心' },
				component: () => import('@/views/TailSelect')
			},
			{
				path: 'file-upload',
				name: 'side-menu-file-upload',
				meta: { title: '管理中心' },
				component: () => import('@/views/FileUpload')
			},
			{
				path: 'wysiwyg-editor',
				name: 'side-menu-wysiwyg-editor',
				meta: { title: '管理中心' },
				component: () => import('@/views/WysiwygEditor')
			},
			{
				path: 'validation',
				name: 'side-menu-validation',
				meta: { title: '管理中心' },
				component: () => import('@/views/Validation')
			},
			{
				path: 'chart',
				name: 'side-menu-chart',
				meta: { title: '管理中心' },
				component: () => import('@/views/Chart')
			},
			{
				path: 'slider',
				name: 'side-menu-slider',
				meta: { title: '管理中心' },
				component: () => import('@/views/Slider')
			},
			{
				path: 'image-zoom',
				name: 'side-menu-image-zoom',
				meta: { title: '管理中心' },
				component: () => import('@/views/ImageZoom')
			},
			{
				path: 'profile',
				name: 'profile',
				meta: { title: '个人资料' },
				component: () => import('@/views/Profile')
			},
			{
				path: 'invitation',
				name: 'invitation',
				meta: { title: '推广赚钱' },
				component: () => import('@/views/Invitation')
			},
			{
				path: 'app',
				name: 'app',
				meta: { title: '应用中心' },
				component: () => import('@/views/App')
			},
			{
				path: 'myApp',
				name: 'myApp',
				meta: { title: '综合应用' },
				component: () => import('@/views/MyApp')
			},
			{
				path: 'terminal',
				name: 'myApp',
				meta: { title: '综合应用全部终端' },
				component: () => import('@/views/MyTerminal'),
			},
			{
				path: 'AppDatails',
				name: 'myApp',
				meta: { title: '综合应用详情' },
				component: () => import('@/views/AppDatails')
			},
			{
				path: 'myApp/CreateAn',
				name: 'myApp',
				meta: { title: '创建综合应用', },
				component: () => import('@/views/CreateAn')
			},
			{
				path: 'supplyChainApp',
				name: 'supplyChainApp',
				meta: { title: '供应链应用' },
				component: () => import('@/views/supply-chain-page/index')
			},
			{
				path: 'supplyChainApp/CreateAn',
				name: 'supplyChainApp',
				meta: { title: '创建供应链应用' },
				component: () => import('@/views/create-supply-chain-page/index')
			},
			{
				path: 'SupplyChainAppDetails',
				name: 'supplyChainApp',
				meta: { title: '供应链应用详情' },
				component: () => import('@/views/supply-chain-app-detail-page/index')
			},
			{
				path: 'chat/CreateAn',
				meta: { title: '消息中心详情' },
				component: () => import('@/views/ChatDetails')
			}
		]
	},
	{
		path: '/register',
		name: 'register',
		meta: { title: '注册账户' },
		component: () => import('@/views/Register')
	},
	{
		path: '/isRegister',
		name: 'isRegister',
		meta: { title: '忘记密码' },
		component: () => import('@/views/isRegister')
	},
	{
		path: '/404',
		name: 'error-page',
		meta: { title: '管理中心' },
		component: () => import('@/views/ErrorPage')
	},
	{
		path: "*",
		redirect: '/404',
		hidden: true,
	}
];






const router = new VueRouter({
	mode: 'hash',  //history
	// mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	}
});


router.beforeEach((to, from, next) => {


	document.title = to.meta.title ? to.meta.title + ' - 行业首家淘客,商城数字营销&管理系统' : '行业首家淘客,商城数字营销&管理系统'

	if (to.path != '/' && to.path != '/register' && to.path != '/isRegister' && to.path != '/m_index' && !to.path.startsWith('/index/')) {
		request({
			url: '/api/sign/status',
			method: 'POST'
		}).then(res => {
			res.data.fin_invalid = Number(res.data.fin_invalid)
			res.data.fin_valid = Number(res.data.fin_valid)
			Cookies.set('userInfo', res.data)
			Cookies.set('discount', res.data.discount)
			// 用户登录未失效， 直接跳转到会员中心页面
			if (to.path === '/') {
				next('/Dashboard')
			}
		}).catch(err => {
			next('/login')
		})
	}
	next()
})



export default router;
