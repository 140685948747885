import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";


// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
//
// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
//
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import 'wowjs/css/libs/animate.css';

import "./components/global";
import "./utils";
import "./libs";

import * as filters from './libs/filters'

Object.keys(filters).forEach(key=>{
  Vue.filter(key,filters[key])//插入过滤器名和对应方法
})

// Midone Theme
import "./assets/sass/app2.scss";
import "./assets/sass/zhiying.scss";

import VueAwesomeSwiper from 'vue-awesome-swiper'


// touch
import touch from 'vue-directive-touch';
Vue.use(touch);

// 复制
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.config.productionTip = false;

// import style (>= Swiper 6.x)
// import 'swiper/swiper-bundle.css'
// 降级到4：6的effect效果无效
import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});

