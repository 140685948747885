import axios from 'axios'

import { Message } from 'element-ui'
 


// const token = false



// create an axios instance
const service = axios.create({
  timeout: 15000 // request timeout
})



// request interceptor
service.interceptors.request.use(
  config => {
    // if (token) {
    //   // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    //   config.headers.Authorization = token
    // }
    return config
  },
  response => {
    console.log(response, 'response')
  },
  error => {
    // Do something with request error
    // console.log("出错啦",error) // for debug
  }
)

// response interceptor
service.interceptors.response.use(response => {


  if (response.status != 200) {

    Message.error(`${response.statusText} :${response.status}`);

  }


  return response.data
},
  error => {


    if (error.response.status !== 200 && error.response.status !== 401) {
      Message.error(error.response.data.msg)
    }



    return Promise.reject( error.response )
  }
)



export default function request(param) {
  return new Promise((resolve, reject) => {
    service({
      url: param.url,
      method: param.method || 'GET',
      data: param.data
    }).then((result) => {
 
      resolve(result)
    }).catch((err) => {
 
      reject(err)
    });
  })
}





// export default service