<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {

  },
  created() {},
 
};
</script>
 

<style scoped>
#app {
  width: 100%;
  height: 100%;
}
</style>