import Vue from "vue";
import Vuex from "vuex";
import sideMenu from "./side-menu";

Vue.use(Vuex);



export default new Vuex.Store({
  modules: {
    sideMenu: sideMenu,
  },
  state: {
    userInfo: 'test'
  }
});
